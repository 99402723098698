import * as React from 'react';
import Checkbox from 'wix-style-react/dist/src/Checkbox';
import CustomModalLayout from 'wix-style-react/dist/src/CustomModalLayout';
import Text from 'wix-style-react/dist/src/Text';
import { ModalTheme } from 'wix-style-react/dist/src/BaseModalLayout';
import { createSlotsContainer } from '@wix/editor-elements-integrations/dist/thunderbolt/createSlotsContainer';
import { MessageModalLayoutProps } from 'wix-style-react/dist/src/MessageModalLayout';
import type { IWsrCustomModalLayoutProps } from '../WsrCustomModalLayout.props';
import {
  HEADER_DIVIDER_VISIBILITY,
  THEME_OPTIONS,
} from '../WsrCustomModalLayout.props';
import { usePlatformIcon } from '../../../providers/usePlatformIcon';
import { getDataAttributes } from '../../../common/utils';
import { st, classes } from './WsrCustomModalLayout.st.css';

const AUTO = 'auto' as const;

const HEADER_DIVIDER_VISIBILITY_VALUES = {
  [HEADER_DIVIDER_VISIBILITY.auto]: AUTO,
  [HEADER_DIVIDER_VISIBILITY.always]: true,
  [HEADER_DIVIDER_VISIBILITY.never]: false,
};

const WsrCustomModalLayout: React.FC<IWsrCustomModalLayoutProps> = props => {
  const {
    id,
    className,
    theme,
    showHeader,
    title,
    subtitle,
    showHeaderDivider,
    removeContentPadding,
    showFooter,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonPrefix,
    primaryButtonSuffix,
    primaryButtonLink,
    primaryButtonDisabled,
    addSecondaryAction,
    secondaryButtonPrefix,
    secondaryButtonSuffix,
    secondaryButtonLink,
    sideActionsCheckbox,
    sideActionsCheckboxTitle,
    showFooterDivider,
    footnote,
    footnoteContent,
    footnoteLinkText,
    footnoteLinkUrl,
    helpButton,
    width,
    height,
    contentContainer,
    onCloseButtonClick,
    onCheckboxToggle,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    onHelpButtonClick,
  } = props;

  const [isCheckboxChecked, setIsCheckboxChecked] =
    React.useState<boolean>(false);

  const modalTheme = theme
    ? Object.keys(THEME_OPTIONS).find(
        key => THEME_OPTIONS[key as keyof typeof THEME_OPTIONS] === theme,
      )
    : undefined;

  const headerDividerValue =
    showHeader && showHeaderDivider
      ? HEADER_DIVIDER_VISIBILITY_VALUES[showHeaderDivider]
      : undefined;

  const footerDividerValue = showFooterDivider
    ? HEADER_DIVIDER_VISIBILITY_VALUES[showFooterDivider]
    : undefined;

  const headerTitle = showHeader && title;
  const headerSubtitle = showHeader && subtitle;

  const primaryButtonPrefixIcon = usePlatformIcon({
    svgId: primaryButtonPrefix?.svgId,
  });
  const primaryButtonSuffixIcon = usePlatformIcon({
    svgId: primaryButtonSuffix?.svgId,
  });
  const secondaryButtonPrefixIcon = usePlatformIcon({
    svgId: secondaryButtonPrefix?.svgId,
  });
  const secondaryButtonSuffixIcon = usePlatformIcon({
    svgId: secondaryButtonSuffix?.svgId,
  });

  const primaryButtonProps =
    showFooter &&
    (primaryButtonPrefix?.svgId ||
      primaryButtonSuffix?.svgId ||
      primaryButtonDisabled)
      ? {
          suffixIcon: primaryButtonSuffixIcon,
          prefixIcon: primaryButtonPrefixIcon,
          disabled: primaryButtonDisabled,
        }
      : undefined;

  const secondaryButtonProps =
    showFooter &&
    addSecondaryAction &&
    (secondaryButtonPrefix?.svgId || secondaryButtonSuffix?.svgId)
      ? {
          suffixIcon: secondaryButtonSuffixIcon,
          prefixIcon: secondaryButtonPrefixIcon,
        }
      : undefined;

  const primaryButtonTitle = showFooter ? primaryButtonText : undefined;

  const secondaryButtonTitle =
    showFooter && addSecondaryAction ? secondaryButtonText : undefined;

  const primaryButtonOnClick =
    showFooter && (primaryButtonLink || onPrimaryButtonClick)
      ? () => {
          if (primaryButtonLink) {
            window.open(primaryButtonLink, '_blank');
          }
          onPrimaryButtonClick?.({ type: 'primaryButtonClick' });
        }
      : undefined;

  const secondaryButtonOnClick =
    showFooter &&
    addSecondaryAction &&
    (secondaryButtonLink || onSecondaryButtonClick)
      ? () => {
          if (secondaryButtonLink) {
            window.open(secondaryButtonLink, '_blank');
          }

          onSecondaryButtonClick?.({ type: 'secondaryButtonClick' });
        }
      : undefined;

  const handleCloseButtonClick: MessageModalLayoutProps['onCloseButtonClick'] =
    () => {
      return onCloseButtonClick?.({ type: 'closeButtonClick' });
    };

  const handleCheckboxToggle = (isChecked: boolean) => {
    return onCheckboxToggle?.({ type: 'checkboxToggle', isChecked });
  };

  const sideActions = sideActionsCheckbox && (
    <Checkbox
      checked={isCheckboxChecked}
      onChange={() => {
        setIsCheckboxChecked(!isCheckboxChecked);
        handleCheckboxToggle(!isCheckboxChecked);
      }}
    >
      {sideActionsCheckboxTitle}
    </Checkbox>
  );

  const modalFootnote = footnote && (
    <Text size="small">
      {footnoteContent}{' '}
      {footnoteLinkText && (
        <a
          href={footnoteLinkUrl ? footnoteLinkUrl : ''}
          target="_blank"
          rel="noreferrer"
        >
          {footnoteLinkText}
        </a>
      )}
    </Text>
  );

  const handleHelpButtonClick = helpButton
    ? () => {
        onHelpButtonClick?.({ type: 'helpButtonClick' });
      }
    : undefined;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <CustomModalLayout
        theme={modalTheme as ModalTheme}
        title={headerTitle}
        subtitle={headerSubtitle}
        showHeaderDivider={headerDividerValue}
        removeContentPadding={removeContentPadding}
        primaryButtonText={primaryButtonTitle}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonText={secondaryButtonTitle}
        secondaryButtonProps={secondaryButtonProps}
        primaryButtonOnClick={primaryButtonOnClick}
        secondaryButtonOnClick={secondaryButtonOnClick}
        sideActions={sideActions}
        showFooterDivider={footerDividerValue}
        footnote={modalFootnote}
        width={width}
        height={height}
        onHelpButtonClick={handleHelpButtonClick}
        onCloseButtonClick={handleCloseButtonClick}
      >
        <div className={st(classes.contentContainer)}>{contentContainer}</div>
      </CustomModalLayout>
    </div>
  );
};

export default createSlotsContainer(WsrCustomModalLayout);
