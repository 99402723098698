import * as React from 'react';
import {
  SettingsPanelContainer as WbuSettingsPanelContainer,
  WixBaseUiEnvironmentProvider,
} from '@wix/wix-base-ui/lib/esm';
import { ISettingsPanelContainerProps } from '../SettingsPanelContainer.props';
import { getDataAttributes } from '../../../common/utils';
import { st, classes } from './style/SettingsPanelContainer.st.css';

const SettingsPanelContainer: React.FC<ISettingsPanelContainerProps> =
  props => {
    const { id, className, children = () => [], theme, debug } = props;

    // This class is a necessary identifier for Thunderbolt container components
    const containerClassById = `${id}-container`;
    // Use facelift as the proper theme for classic
    const finalTheme = theme === 'classic' ? 'facelift' : theme;
    return (
      <div
        id={id}
        {...getDataAttributes(props)}
        className={`${className} ${st(classes.root, {
          debug,
        })} ${containerClassById}`}
      >
        <WixBaseUiEnvironmentProvider theme={finalTheme} madefor={false}>
          <WbuSettingsPanelContainer>
            {
              // @ts-ignore
              children()
            }
          </WbuSettingsPanelContainer>
        </WixBaseUiEnvironmentProvider>
      </div>
    );
  };

export default SettingsPanelContainer;
