export const helpIds = {
  layoutPanel: '',
};

export const TestIds = {
  baseAvatar: 'base-avatar',
  layoutPanel: {
    root: 'layoutPanelRoot',
    circleSizeSlider: 'layoutCircleSizeSlider',
  },
};

export const Selectors = {
  wrapper: '.avatarWrapper',
  root: '.root',
  avatarContent: '.root::content',
};

export const CssVarPrefix = {
  globalPrefix: '--',
  scopedPrefix: '--WutAvatar-',
};

export const CssVars = {
  circleSize: 'circleSize',
};
