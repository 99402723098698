import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      tooltip: '',
      iconType: 'Info',
      description: 'This is a description text',
      label: 'Label Title',
    },
    ...{},
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
