import type { AvatarGroupItem } from 'wix-ui-tpa/dist/src/components/AvatarGroup/types';

export const defaultAvatars = [
  {},
  { name: 'anonymous' },
  { name: 'Eve', src: 'https://randomuser.me/api/portraits/women/87.jpg' },
  { name: 'John', src: 'https://randomuser.me/api/portraits/men/69.jpg' },
] as Array<AvatarGroupItem>;

export const TestIds = {
  baseAvatar: 'base-avatar',
  layoutPanel: {
    root: 'layoutPanelRoot',
    circleSizeSlider: 'layoutCircleSizeSlider',
    spaceSizeSlider: 'layoutSpaceSizeSlider',
    orientationThumbnails: 'orientationThumbnails',
    directionThumbnails: 'directionThumbnails',
    maxAvatarsInput: 'maxAvatarsInput',
    hiddenAvatarsIndicationToggle: 'hiddenAvatarsIndicationToggle',
    avatarsOverlapToggle: 'avatarsOverlapToggle',
  },
};

export const Selectors = {
  root: '.root',
  avatarContainer: '.root::avatarContainer:not(:first-of-type)',
};

export const CssVarPrefix = {
  global: '--',
  scoped: '--WutAvatarGroup-',
  corvid: '--corvid-',
};

export const CssVars = {
  avatarCircleSize: 'avatarCircleSize',
  spaceSize: 'spaceSize',
  overlap: 'overlap',
};
