import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import type { IWbuRichTextProps } from '../../../components/WbuRichText/WbuRichText.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (props: Partial<IWbuRichTextProps>) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
