import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';
import type { DefaultContainerProps } from '@wix/editor-elements-types/thunderbolt';
import { OnTitleChangePayload } from './corvid/titleChangePropsSDKFactory';
import { OnPrimaryButtonClickPayload } from './corvid/primaryButtonClickPropsSDKFactory';
import { OnSecondaryButtonClickPayload } from './corvid/secondaryButtonClickPropsSDKFactory';
import { OnBackButtonClickPayload } from './corvid/backButtonClickPropsSDKFactory';

export const PAGE_TYPES = {
  FORM: 'form',
  DASHBOARD: 'dashboard',
};
const pageTypes = [...([...Object.values(PAGE_TYPES)] as const)];
type PageType = typeof pageTypes[number];

export const FORM_LAYOUTS = {
  SINGLE_COLUMN: 'single column',
  TWO_COLUMNS: 'two columns',
};
const formLayouts = [...([...Object.values(FORM_LAYOUTS)] as const)];
type FormLayout = typeof formLayouts[number];

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    pageType: schemaBuilder
      .stringEnum<PageType>(pageTypes)
      .default(PAGE_TYPES.FORM),
    formLayout: schemaBuilder
      .stringEnum<FormLayout>(formLayouts)
      .default(FORM_LAYOUTS.TWO_COLUMNS),
    pageHeight: schemaBuilder.integer().default(1000),

    editableTitle: schemaBuilder.boolean().default(false),
    title: schemaBuilder.longString().default('Untitled Page'),
    editableTitleValue: schemaBuilder.longString().default('Untitled Page'),
    editableTitleDefaultValue: schemaBuilder.longString().default(''),
    editableTitleMaxLength: schemaBuilder.integer().default(120),
    editableTitleAutoFocus: schemaBuilder.boolean().default(false),
    subtitle: schemaBuilder.longString().default(''),

    showPrimaryButton: schemaBuilder.boolean().default(true),
    primaryButtonValue: schemaBuilder.shortString().default('Save'),
    showSecondaryButton: schemaBuilder.boolean().default(true),
    secondaryButtonValue: schemaBuilder.shortString().default('Cancel'),

    showBackButton: schemaBuilder.boolean().default(false),

    mainSlot: schemaBuilder.slot(),
    secondSlot: schemaBuilder.slot(),
  }),
  { dmPublish: 'draft' },
).definePanels(({ settingsPanel, layoutPanel }) => {
  layoutPanel?.controls.push('pageType', 'formLayout', 'pageHeight');
  settingsPanel?.controls.omit('pageType', 'formLayout', 'pageHeight');

  layoutPanel?.controls.formLayout.isVisible(
    ({ props: { pageType } }) => pageType === 'form',
  );
  settingsPanel?.controls.title.isVisible(
    ({ props: { editableTitle } }) => !editableTitle,
  );
  settingsPanel?.controls.primaryButtonValue.isVisible(
    ({ props: { showPrimaryButton } }) => showPrimaryButton,
  );
  settingsPanel?.controls.secondaryButtonValue.isVisible(
    ({ props: { showSecondaryButton } }) => showSecondaryButton,
  );
  settingsPanel?.controls.editableTitleValue.isVisible(
    ({ props: { editableTitle } }) => editableTitle,
  );
  settingsPanel?.controls.editableTitleDefaultValue.isVisible(
    ({ props: { editableTitle } }) => editableTitle,
  );
  settingsPanel?.controls.editableTitleMaxLength.isVisible(
    ({ props: { editableTitle } }) => editableTitle,
  );
  settingsPanel?.controls.editableTitleAutoFocus.isVisible(
    ({ props: { editableTitle } }) => editableTitle,
  );
});

export type PageSDKActions = {
  onPrimaryButtonClick(payload: OnPrimaryButtonClickPayload): void;
  onSecondaryButtonClick(payload: OnSecondaryButtonClickPayload): void;
  onBackButtonClick(payload: OnBackButtonClickPayload): void;
  onTitleChange(payload: OnTitleChangePayload): void;
};

export type IWsrPageProps = InferExtendedPropsSchema<typeof props> &
  PageSDKActions &
  DefaultContainerProps & { className?: string };

export default props;
