import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import type { IWsrCustomModalLayoutProps } from '../../../components/WsrCustomModalLayout/WsrCustomModalLayout.props';

const mapActionsToProps = ({ updateProps }) => ({
  updateComponentPropsInViewer: (
    props: Partial<IWsrCustomModalLayoutProps>,
  ) => {
    updateProps(props);
  },
});

const mapStateToProps = ({ editorType }) => ({
  editorType,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
