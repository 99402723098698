import * as React from 'react';
import {
  Thumbnails,
  Composites,
  TextLabel,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';
import type { ManagedOptionDataType } from '@wix/editor-elements-types/leanComponents';
import { defaultIcon } from '@wix/lean-components-infra/src/runtime/leanPanels/Components/Assets/manageOptions_defaultIcon';
import { Constants } from '../constants';
import { IWbuThumbnailsProps } from '../WbuThumbnails.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuThumbnails.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuThumbnails: React.FC<IWbuThumbnailsProps> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipInfo,
    setNumberOfColumns,
    thumbnailHeight,
    displayLabels,
    options,
    showEmptyThumbnail,
    divider,
    updateComponentPropsInViewer,
    onChange,
    editorType,
  } = props;

  const enrichedOptions = options.map((item: ManagedOptionDataType) => {
    const newItem: ManagedOptionDataType = {
      ...item,
      label: displayLabels ? item.label : undefined,
    };
    const __html =
      newItem.illustration!.svgId ===
      `<svg data-svg-id="fallback-defaultSvg" />`
        ? defaultIcon()
        : newItem.illustration!.svgId;
    return {
      ...newItem,
      illustration: <div dangerouslySetInnerHTML={{ __html }} />,
    };
  });

  const _onChange = (selectedOptionValue: string) => {
    const optionsWithNewSelectedValue = options.map(option => {
      return {
        ...option,
        isDefault: option.value === selectedOptionValue,
      };
    });
    updateComponentPropsInViewer({ options: optionsWithNewSelectedValue });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const selectedOption = enrichedOptions.find(option => option.isDefault);

  const getValue = () =>
    selectedOption
      ? selectedOption.value
      : showEmptyThumbnail
      ? Constants.noneValue
      : '';

  const getNoneThumbnailProps = () =>
    showEmptyThumbnail
      ? {
          value: Constants.noneValue,
          label: displayLabels ? Constants.NoneLabel : '',
        }
      : undefined;

  // We add global style this way because `global.scss` files are not properly loaded for
  // this component.
  const Style = () => {
    const css = `
    .composite-with-info-icon > .has-tooltip.info-icon-tooltip {
      top: 24px;
    }`;
    return <style dangerouslySetInnerHTML={{ __html: css }} />;
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      editorType={editorType}
    >
      <Composites.Thumbnails>
        <Style />
        <TextLabel value={fieldTitle} />
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        <Thumbnails
          className={Constants.ThumbnailsClassName}
          options={enrichedOptions}
          onChange={_onChange}
          value={getValue()}
          noneThumbnailProps={getNoneThumbnailProps()}
          maxThumbsPerRow={setNumberOfColumns}
          fixedRatio={thumbnailHeight === Constants.fixedRatio}
        />
      </Composites.Thumbnails>
    </WbuContainer>
  );
};

export default WbuThumbnails;
