import * as React from 'react';
import {
  Composites,
  NumericInput,
  NumericInputProps,
} from '@wix/wix-base-ui/lib/esm';
import type {
  UnitSuffixTypes,
  IWbuNumericInput,
} from '../WbuNumericInput.props';
import WbuContainer from '../../WbuContainer/WbuContainer';

import './WbuNumericInput.global.scss';
import { getDataAttributes } from '../../../common/utils';

const UNITS: Record<UnitSuffixTypes, NumericInputProps['unit']> = {
  Pixels: 'px',
  Percentage: 'percent',
  Degrees: 'angle',
  None: undefined,
};

const WbuNumericInput: React.FC<IWbuNumericInput> = props => {
  const {
    id,
    className,
    title,
    value,
    steps: step,
    minValue,
    maxValue,
    unitSuffix,
    numberOfFractions: fractionDigits,
    stepControls,
    divider,
    onChange,
    updateComponentPropsInViewer,
    editorType,
  } = props;

  const _onChange = (val: number) => {
    updateComponentPropsInViewer({ value: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      editorType={editorType}
    >
      <Composites.NumericInput>
        <NumericInput
          placeholder={'Placeholder'}
          min={minValue}
          max={maxValue}
          step={step}
          shouldRoundByStep={false}
          unit={UNITS[unitSuffix]}
          prefix={title || 'Field title'}
          value={value}
          fractionDigits={fractionDigits}
          stepControls={stepControls}
          onChange={_onChange}
        />
      </Composites.NumericInput>
    </WbuContainer>
  );
};

export default WbuNumericInput;
