import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';
import { OnKeyPressPayload } from './corvid/keyPressPropsSDKFactory';

export const PREFIX_AND_SUFFIX_TYPES = {
  none: 'None',
  text: 'Text',
  icon: 'Icon',
};
const prefixAndSuffixTypeOptions = [
  ...([...Object.values(PREFIX_AND_SUFFIX_TYPES)] as const),
];
export type PrefixAndSuffixTypeOptions =
  typeof prefixAndSuffixTypeOptions[number];

const SIZES: { [key: string]: string } = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
};

const sizes = [...([...Object.values(SIZES)] as const)];

export const LABEL_POSITIONS: { [key: string]: string } = {
  top: 'On top',
  left: 'Before',
  right: 'After',
};
const labelPositions = [...([...Object.values(LABEL_POSITIONS)] as const)];

export const BORDERS: { [key: string]: string } = {
  standard: 'Standard',
  round: 'Round',
  bottomLine: 'Underline only',
  none: 'None',
};

const borders = [...([...Object.values(BORDERS)] as const)];

/**
 * The props.ts file is the contract between the React component and Wix ecosystem
 * Through this file you can declare panels, SDKs and behaviours.
 * To learn more about the various capabillities, read [lean components guide](https://bo.wix.com/pages/editor-elements-docs/docs/development/lean-components/index)
 */
const props = defineLeanCompProps(({ schemaBuilder }) => ({
  label: schemaBuilder.shortString().default('Text Input'),
  showTooltip: schemaBuilder.boolean().default(false),
  infoContent: schemaBuilder.longString().default(''),
  placeholder: schemaBuilder.longString().default(''),
  value: schemaBuilder.longString().default(''),
  prefixType: schemaBuilder
    .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
    .default(PREFIX_AND_SUFFIX_TYPES.none),
  prefixIcon: schemaBuilder.svg(),
  prefixValue: schemaBuilder.shortString().default(''),
  suffixType: schemaBuilder
    .stringEnum<PrefixAndSuffixTypeOptions>(prefixAndSuffixTypeOptions)
    .default(PREFIX_AND_SUFFIX_TYPES.none),
  suffixIcon: schemaBuilder.svg(),
  suffixValue: schemaBuilder.shortString().default(''),
  maxLength: schemaBuilder.integer().default(100),
  required: schemaBuilder.boolean().default(false),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
  border: schemaBuilder.stringEnum(borders).default(BORDERS.standard),
  labelPosition: schemaBuilder
    .stringEnum(labelPositions)
    .default(LABEL_POSITIONS.top),
}))
  .definePanels(({ settingsPanel, layoutPanel }) => {
    settingsPanel?.controls.createSection(['showTooltip', 'infoContent']);
    settingsPanel?.controls.createSection(['placeholder', 'value']);
    settingsPanel?.controls.createSection(
      ['prefixType', 'prefixIcon', 'prefixValue'],
      {
        name: 'dsgnsys_WsrInput_prefix_section_label',
      },
    );
    settingsPanel?.controls.createSection(
      ['suffixType', 'suffixIcon', 'suffixValue'],
      {
        name: 'dsgnsys_WsrInput_suffix_section_label',
      },
    );
    settingsPanel?.controls.createSection(['maxLength', 'required'], {
      name: 'dsgnsys_WsrInput_rules_section_label',
    });
    settingsPanel?.controls.createSection(['size'], {
      name: 'dsgnsys_WsrInput_size_section_label',
    });
    settingsPanel?.controls.createSection(['border'], {
      name: 'dsgnsys_WsrInput_style_section_label',
    });
    settingsPanel?.controls.omit('labelPosition');
    layoutPanel?.controls.push('labelPosition');
    settingsPanel?.controls.infoContent.isVisible(
      ({ props: { showTooltip } }) => showTooltip,
    );
    settingsPanel?.controls.prefixIcon.isVisible(
      ({ props: { prefixType } }) => prefixType === 'Icon',
    );
    settingsPanel?.controls.prefixValue.isVisible(
      ({ props: { prefixType } }) => prefixType === 'Text',
    );
    settingsPanel?.controls.suffixIcon.isVisible(
      ({ props: { suffixType } }) => suffixType === 'Icon',
    );
    settingsPanel?.controls.suffixValue.isVisible(
      ({ props: { suffixType } }) => suffixType === 'Text',
    );
    settingsPanel?.controls.prefixType.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.suffixType.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.size.modify({
      controlType: 'radioButtons',
    });
    layoutPanel?.controls.labelPosition.modify({
      controlType: 'radioButtons',
    });
    settingsPanel?.controls.prefixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
    settingsPanel?.controls.suffixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
  })
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.changeable],
  }));

export type InputSDKActions = {
  onKeyPress(payload: OnKeyPressPayload): void;
};

type IWsrInputVeloProps = {
  error: boolean;
  warning: boolean;
  loading: boolean;
  errorMessage: string;
  warningMessage: string;
  loadingMessage: string;
  disabled: boolean;
  readOnly: boolean;
};

export type IWsrInputProps = InferExtendedPropsSchema<typeof props> &
  InputSDKActions &
  IWsrInputVeloProps & { className?: string };

export default props;
