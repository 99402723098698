import type { LinkProps } from '@wix/editor-elements-types/thunderbolt';

export function usePlatformLink(linkProps: LinkProps | undefined) {
  if (!linkProps) {
    return {};
  }

  // @ts-ignore
  const { href, target, rel, anchorDataId, linkPopupId } =
    linkProps.resolvedLink ? linkProps.resolvedLink : linkProps;

  return {
    href,
    target,
    rel,
    'data-anchor': anchorDataId,
    'data-popupid': linkPopupId,
  };
}
