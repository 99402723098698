import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      divider: 'long',
      constrainCorners: true,
      max: 999,
      min: 0,
      value: 0,
      tooltip: '',
      label: 'Corner Radius',
    },
    ...{},
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
