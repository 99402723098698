import * as React from 'react';
import PreviewWidget from 'wix-style-react/dist/src/PreviewWidget';
import { createSlotsContainer } from '@wix/editor-elements-integrations/dist/thunderbolt/createSlotsContainer';
import classNames from 'classnames';
import type { IWsrPreviewWidgetProps } from '../WsrPreviewWidget.props';
import { getDataAttributes } from '../../../common/utils';
import { classes } from './WsrPreviewWidget.st.css';

const WsrPreviewWidget: React.FC<IWsrPreviewWidgetProps> = props => {
  const {
    id,
    className,
    backgroundFill,
    previewWidgetContainer,
    backgroundColor,
    contentOutline,
  } = props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, classes.root)}
    >
      <PreviewWidget
        className={classes.previewWidgetRoot}
        skin={backgroundFill}
        backgroundColor={backgroundColor}
        contentOutline={contentOutline}
      >
        <div className={classes.previewWidgetContainer}>
          {previewWidgetContainer}
        </div>
      </PreviewWidget>
    </div>
  );
};

export default createSlotsContainer(WsrPreviewWidget);
