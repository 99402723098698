import * as React from 'react';

import { AvatarBase } from 'wix-ui-tpa/dist/src/components/Avatar/Avatar.base';
import classNames from 'classnames';
import type { IWutAvatarProps } from '../WutAvatar.props';
import { TestIds } from '../constants';
import { st, classes } from './style/WutAvatar.component.st.css';

const getName = (firstName: string, lastName: string): string => {
  let name = lastName || '';

  if (firstName) {
    name = lastName ? `${firstName} ${lastName}` : firstName;
  }

  return name;
};

/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabillities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const WutAvatar: React.FC<IWutAvatarProps> = props => {
  const { id, firstName, lastName, src, onMouseEnter, onMouseLeave } = props;
  const { className, stylableClassName } = props as any; // todo: remove it once this prop would be added for the lean

  return (
    <div
      id={id}
      className={classNames(className, classes.avatarWrapper)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <AvatarBase
        className={st(classes.root, stylableClassName)}
        data-hook={TestIds.baseAvatar}
        name={getName(firstName, lastName)}
        src={src}
      />
    </div>
  );
};

export default WutAvatar;
