import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

import { OnCloseButtonClickPayload } from './corvid/closeButtonClickPropsSDKFactory';
import { OnCheckboxTogglePayload } from './corvid/checkboxTogglePropsSDKFactory';
import { OnPrimaryButtonClickPayload } from './corvid/primaryButtonClickPropsSDKFactory';
import { OnSecondaryButtonClickPayload } from './corvid/secondaryButtonClickPropsSDKFactory';
import { OnHelpButtonClickPayload } from './corvid/helpButtonClickPropsSDKFactory';

export const THEME_OPTIONS = {
  standard: 'Standard',
  premium: 'Premium',
  destructive: 'Destructive',
};

const props = defineLeanCompProps(
  ({ schemaBuilder }) => ({
    theme: schemaBuilder
      .stringEnum(Object.values(THEME_OPTIONS))
      .default(THEME_OPTIONS.standard),
    title: schemaBuilder.longString().default('Discard changes'),
    content: schemaBuilder
      .longString()
      .default(
        'Are you sure you want to leave this page? Your changes won’t be saved.',
      ),
    illustration: schemaBuilder.svg(),
    primaryButtonText: schemaBuilder.shortString().default('Submit'),
    primaryButtonLink: schemaBuilder.shortString().default(''),
    primaryButtonPrefix: schemaBuilder.svg(),
    primaryButtonSuffix: schemaBuilder.svg(),
    secondaryButtonText: schemaBuilder.shortString().default('Cancel'),
    secondaryButtonLink: schemaBuilder.shortString().default(''),
    secondaryButtonPrefix: schemaBuilder.svg(),
    secondaryButtonSuffix: schemaBuilder.svg(),
    sideActionsCheckbox: schemaBuilder.boolean().default(false),
    sideActionsCheckboxTitle: schemaBuilder
      .longString()
      .default('Checkbox label'),
    footnote: schemaBuilder.boolean().default(false),
    footnoteContent: schemaBuilder
      .longString()
      .default('By proceeding, you agree to Wix Terms of Use'),
    footnoteLink: schemaBuilder.boolean().default(false),
    footnoteLinkText: schemaBuilder.longString().default('Learn more'),
    footnoteLinkUrl: schemaBuilder.shortString().default(''),
    helpButton: schemaBuilder.boolean().default(false),
    helpButtonUrl: schemaBuilder.shortString().default(''),
  }),
  { dmPublish: 'draft' },
).definePanels(({ settingsPanel }) => {
  settingsPanel?.controls.sideActionsCheckboxTitle.isVisible(
    ({ props: { sideActionsCheckbox } }) => sideActionsCheckbox,
  );
  settingsPanel?.controls.footnoteContent.isVisible(
    ({ props: { footnote } }) => footnote,
  );
  settingsPanel?.controls.footnoteLink.isVisible(
    ({ props: { footnote } }) => footnote,
  );
  settingsPanel?.controls.footnoteLinkText.isVisible(
    ({ props: { footnote, footnoteLink } }) => footnote && footnoteLink,
  );
  settingsPanel?.controls.footnoteLinkUrl.isVisible(
    ({ props: { footnote, footnoteLink } }) => footnote && footnoteLink,
  );
  settingsPanel?.controls.helpButtonUrl.isVisible(
    ({ props: { helpButton } }) => helpButton,
  );
  settingsPanel?.controls.primaryButtonPrefix.modify({
    preset: 'WSR_ICONS_SMALL',
  });
  settingsPanel?.controls.primaryButtonSuffix.modify({
    preset: 'WSR_ICONS_SMALL',
  });
  settingsPanel?.controls.secondaryButtonPrefix.modify({
    preset: 'WSR_ICONS_SMALL',
  });
  settingsPanel?.controls.secondaryButtonSuffix.modify({
    preset: 'WSR_ICONS_SMALL',
  });
});

export type MessageModalLayoutSDKActions = {
  onCloseButtonClick(payload: OnCloseButtonClickPayload): void;
  onCheckboxToggle(payload: OnCheckboxTogglePayload): void;
  onPrimaryButtonClick(payload: OnPrimaryButtonClickPayload): void;
  onSecondaryButtonClick(payload: OnSecondaryButtonClickPayload): void;
  onHelpButtonClick(payload: OnHelpButtonClickPayload): void;
};

export type IWsrMessageModalLayoutProps = InferExtendedPropsSchema<
  typeof props
> &
  MessageModalLayoutSDKActions & { className?: string };

export default props;
