import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{ verticalPadding: 'small', accordionType: 'radio', items: [] },
    ...{},
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
