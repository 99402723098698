import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      divider: 'long',
      tooltipInfo: '',
      options: [],
      fieldTitle: 'Radio Buttons',
    },
    ...{},
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
