import {
  defineLeanCompProps,
  InferExtendedPropsSchema,
} from '@wix/lean-components-infra';

import type { IElementPropsSDKActions } from '@wix/editor-elements-corvid-utils';

export const SIZES: { [key: string]: string } = {
  medium: 'Medium',
  small: 'Small',
  tiny: 'Tiny',
} as const;
const sizes = Object.values(SIZES);

export const WEIGHTS: { [key: string]: string } = {
  thin: 'Regular',
  normal: 'Medium',
} as const;
const weights = Object.values(WEIGHTS);

export const UNDERLINES: { [key: string]: string } = {
  none: 'Never',
  onHover: 'On mouse hover',
  always: 'Always',
} as const;
const underlines = Object.values(UNDERLINES);

export const SKINS: { [key: string]: string } = {
  standard: 'Standard',
  destructive: 'Destructive',
  premium: 'Premium',
  light: 'Light',
  dark: 'Dark',
} as const;
export const skins = Object.values(SKINS);

const props = defineLeanCompProps(({ schemaBuilder }) => ({
  value: schemaBuilder.shortString().default('Text Button'),
  link: schemaBuilder.link(),
  prefixIcon: schemaBuilder.svg(),
  suffixIcon: schemaBuilder.svg(),
  disabled: schemaBuilder.boolean().default(false),
  size: schemaBuilder.stringEnum(sizes).default(SIZES.medium),
  weight: schemaBuilder.stringEnum(weights).default(WEIGHTS.thin),
  underline: schemaBuilder.stringEnum(underlines).default(UNDERLINES.none),
  skin: schemaBuilder.stringEnum(skins).default(SKINS.standard),
}))
  .definePanels(({ settingsPanel }) => {
    settingsPanel?.controls.createSection(['prefixIcon', 'suffixIcon']);
    settingsPanel?.controls.createSection(['disabled'], {
      name: 'dsgnsys_WsrTextButton_status_section_label',
    });
    settingsPanel?.controls.createSection(['size'], {
      name: 'dsgnsys_WsrTextButton_size_section_label',
    });
    settingsPanel?.controls.createSection(['weight', 'underline', 'skin'], {
      name: 'dsgnsys_WsrTextButton_style_section_label',
    });
    settingsPanel?.controls.prefixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
    settingsPanel?.controls.suffixIcon.modify({
      preset: 'WSR_ICONS_MEDIUM',
    });
    settingsPanel?.controls.size.modify({ controlType: 'radioButtons' });
    settingsPanel?.controls.weight.modify({ controlType: 'radioButtons' });
    settingsPanel?.controls.underline.modify({ controlType: 'radioButtons' });
  })
  .defineSdk(({ sdkMixin }) => ({
    mixins: [sdkMixin.clickable],
  }));

export type IWsrTextButtonProps = InferExtendedPropsSchema<typeof props> &
  IElementPropsSDKActions & { className?: string };

export default props;
