import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      divider: 'long',
      fullWidth: false,
      suffixIcon: {},
      prefixIcon: {},
      size: 'medium',
      skin: 'standard',
      label: 'Button',
      tooltipInfo: '',
      fieldTitle: '',
    },
    ...{},
    ...compData,
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
