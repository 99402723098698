import * as React from 'react';
import { AvatarGroupBase } from 'wix-ui-tpa/dist/src/components/AvatarGroup/AvatarGroup.base';
import { AvatarBase } from 'wix-ui-tpa/dist/src/components/Avatar/Avatar.base';
import type { AvatarGroupItem } from 'wix-ui-tpa';
import classNames from 'classnames';
import type { IWutAvatarGroupProps } from '../WutAvatarGroup.props';
import { defaultAvatars } from '../constants';
import { st, classes } from './style/WutAvatarGroup.component.st.css';

export type WutAvatarItem = AvatarGroupItem & {
  firstName?: string;
  lastName?: string;
};

const getAvatarName = (avatar: WutAvatarItem): AvatarGroupItem => {
  if (avatar.firstName && avatar.lastName) {
    return {
      ...avatar,
      name: `${avatar.firstName} ${avatar.lastName}`,
    };
  }

  return {
    ...avatar,
    name: avatar.firstName || avatar.lastName || '',
  };
};

const combineAvatarsNames = (
  avatars: Array<WutAvatarItem>,
): Array<AvatarGroupItem> => avatars.map(getAvatarName);

const WutAvatarGroup: React.FC<IWutAvatarGroupProps> = props => {
  const {
    id,
    className,
    stylableClassName,
    maxAvatars,
    showHiddenAvatarCount,
    avatars,
  } = props as IWutAvatarGroupProps & {
    className: string;
    stylableClassName: string;
  }; // todo: remove it once this prop would be added for the lean

  return (
    <div
      className={classNames(className, classes.avatarContainerLayout)}
      id={id}
    >
      <AvatarGroupBase
        avatarContainerClassName={classes.avatarContainer}
        className={st(classes.root, stylableClassName)}
        items={
          avatars.length
            ? combineAvatarsNames(avatars as Array<WutAvatarItem>)
            : defaultAvatars
        }
        maxAmount={maxAvatars}
        // @ts-expect-error
        showHiddenAvatarCount={showHiddenAvatarCount}
        renderAvatar={avatarProps => (
          <AvatarBase className={classes.avatarBase} {...avatarProps} />
        )}
      />
    </div>
  );
};

export default WutAvatarGroup;
