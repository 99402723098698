import * as React from 'react';
import { useEffect } from 'react';
import NumberInput, {
  NumberInputProps,
} from 'wix-style-react/dist/src/NumberInput';
import FormField, { LabelPlacement } from 'wix-style-react/dist/src/FormField';
import Input, { InputStatus, InputSize } from 'wix-style-react/dist/src/Input';
import {
  PREFIX_AND_SUFFIX_TYPES,
  BORDERS,
  LABEL_POSITIONS,
} from '../WsrNumberInput.props';

import type { IWsrNumberInputProps } from '../WsrNumberInput.props';
import { getDataAttributes } from '../../../common/utils';

const STATUSES: { [key: string]: string } = {
  error: 'error',
  warning: 'warning',
  loading: 'loading',
};

const generateIconElement = (
  icon: { svgId?: string },
  text: string,
  type: string,
) => {
  if (type === PREFIX_AND_SUFFIX_TYPES.icon && icon?.svgId) {
    return (
      <Input.IconAffix>
        <div dangerouslySetInnerHTML={{ __html: icon.svgId }} />
      </Input.IconAffix>
    );
  }

  if (type === PREFIX_AND_SUFFIX_TYPES.text) {
    return <Input.Affix>{text}</Input.Affix>;
  }

  return null;
};

const WsrNumberInput: React.FC<IWsrNumberInputProps> = props => {
  const {
    id,
    className,
    value: givenValue,
    label,
    showTooltip,
    infoContent,
    labelPlacement,
    placeholderText,
    minAndMaxValues,
    minimumValue,
    maximumValue,
    prefixType,
    prefixIcon,
    prefixValue,
    suffixType,
    suffixIcon,
    suffixValue,
    stepSize,
    required,
    size,
    border,
    validationMessage,
    error,
    warning,
    loading,
    errorMessage,
    warningMessage,
    loadingMessage,
    disabled,
    readOnly,
    onChange,
    onKeyPress,
    onInvalid,
  } = props;

  const [value, setValue] = React.useState<number | string | undefined>(
    givenValue,
  );

  useEffect(() => {
    setValue(givenValue);
  }, [givenValue]);

  const handleInvalid: NumberInputProps['onInvalid'] = invalidValue => {
    return onInvalid?.({ type: 'invalid', value: invalidValue });
  };

  const handleChange: NumberInputProps['onChange'] = (
    newValue,
    stringValue,
  ) => {
    return onChange?.({ type: 'change', value: newValue, stringValue });
  };

  const handleKeyDown: NumberInputProps['onKeyDown'] = event => {
    return onKeyPress?.({ type: 'keyPress', key: event.key });
  };

  const setNewValue = (newValue: number | null, stringValue: string) => {
    setValue(newValue === null ? '' : newValue);
    handleChange(newValue, stringValue);
  };

  const inputStatus = ((error && STATUSES.error) ||
    (warning && STATUSES.warning) ||
    (loading && STATUSES.loading)) as InputStatus;

  const statusMessage =
    (error && errorMessage) ||
    (warning && warningMessage) ||
    (loading && loadingMessage);

  const borderValue = Object.keys(BORDERS).find(
    key => BORDERS[key] === border,
  ) as 'standard' | 'round' | 'bottomLine' | 'none';

  const labelPositionValue = Object.keys(LABEL_POSITIONS).find(
    key => LABEL_POSITIONS[key] === labelPlacement,
  ) as LabelPlacement;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <FormField
        label={label}
        infoContent={showTooltip && infoContent}
        labelPlacement={labelPositionValue}
        required={required}
      >
        <NumberInput
          value={value}
          onChange={setNewValue}
          placeholder={placeholderText}
          min={minAndMaxValues ? minimumValue : undefined}
          max={minAndMaxValues ? maximumValue : undefined}
          prefix={generateIconElement(prefixIcon, prefixValue, prefixType)}
          suffix={generateIconElement(suffixIcon, suffixValue, suffixType)}
          step={stepSize}
          status={inputStatus}
          statusMessage={statusMessage}
          readOnly={readOnly}
          disabled={disabled}
          size={size.toLowerCase() as InputSize}
          invalidMessage={validationMessage}
          border={borderValue}
          onInvalid={handleInvalid}
          onKeyDown={handleKeyDown}
        />
      </FormField>
    </div>
  );
};

export default WsrNumberInput;
