import * as React from 'react';
import SelectableAccordion, {
  SelectableAccordionItemProps,
} from 'wix-style-react/dist/src/SelectableAccordion';
import { getDataAttributes } from '../../../common/utils';
import type { IWsrSelectableAccordionProps } from '../WsrSelectableAccordion.props';
import { st, classes } from './WsrSelectableAccordion.st.css';

const WsrSelectableAccordion: React.FC<IWsrSelectableAccordionProps> =
  props => {
    const { id, className, accordionType, verticalPadding, items, onSelect } =
      props;

    const children =
      typeof props.children === 'function' ? props.children() : props.children;

    const accordionItems = React.useMemo<Array<SelectableAccordionItemProps>>(
      () =>
        children.map((child: React.ReactNode, i: number) => {
          const item =
            items?.length > i
              ? items[i]
              : {
                  initiallyOpen: false,
                  isOpen: false,
                  label: '',
                  value: '',
                };

          return {
            initiallyOpen: item.initiallyOpen,
            open: item.isOpen,
            title: item.label,
            subtitle: item.value,
            content: (
              <div className={st(classes.contentContainer)}>{child}</div>
            ),
          };
        }),
      [children, items],
    );

    const _onSelect = (selectedIds: Array<number>) => {
      onSelect?.({
        type: 'select',
        selectedList: selectedIds.map(selectedId => ({
          id: selectedId,
          label: items[selectedId].label,
          value: items[selectedId].value,
        })),
      });
    };

    return (
      <div
        id={id}
        {...getDataAttributes(props)}
        className={st(className, classes.root)}
      >
        <SelectableAccordion
          onSelectionChanged={_onSelect}
          type={accordionType}
          items={accordionItems}
          verticalPadding={verticalPadding}
        />
      </div>
    );
  };

export default WsrSelectableAccordion;
